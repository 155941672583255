import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WebDevelopmentContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/5.webp" alt="web development company" />
            </div>
            <div className="info">
                <h1>One of The best Top Rated  Web Development company in Hyderabad, India.</h1><br></br>
                <h3 className="title">Web Development</h3>
                <div className="desc">
                    <p><strong>Livasys IT Solutions brings contemporary technology to your company to satisfy all your Web development needs. We as the best web development company in Hyderabad ensures that our in-house professional team utilizes WordPress, Joomla, Drupal, Laravel, Magento, Core PHP language, WooCommerce, and different platforms to complement your business goals.</strong></p>

                    <p>These services are used to design, build, maintain, and increase all types of Web software. Once the front-end design of the website is completed, our development team starts to work.</p>

                    <p>Whether you need to develop an exceptional website or are seeking out professionals who can transform your present website into a brand-new technology platform. Our web development company in Hyderabad offer competent network development services in nearly all elements of the network domain.</p>

                    <h3>How our Web Development services are beneficial for you?</h3>
 

<h5>Fully Control Over Design:</h5>
<p>Your company can change or adjust product supply, pricing, and even company structure, all of which need to be reflected on its page. Our web development company is always there for making changes and modifications to your webpage from time to time according to your needs.</p>

<h5>Improve brand awareness:</h5>
<p>We at our web development company, provide Many of our tools which allow you to increase brand awareness and customer base. With our website development service, you can create a unique atmosphere for your customers. Strengthen your brand personality and build trust. Successful brands usually set trends in their website design. It helps keep your customers optimistic. </p>

<h5>Save advertising costs:</h5>
<p>You can invest in SEO promotion of your website instead of paying expensive online and offline advertising costs. If we compare traditional marketing strategies with digital marketing strategies. Marketing or website development, it turns out that this kind of website development is more effective financially and in reaching audiences. From online marketing to brand promotion, your website design has a powerful influence on your audience. Our web development company in Hyderabad implies the best SEO practices for your website to boost your revenue organically.</p>

<h5>Save time by asking questions:</h5>
<p>Whenever your customers need to know information about your company, the contact form, live chat, comment function or phone number are all a step towards their customers and improve customer reach. But at our web development company, we develop websites that can easily navigate through all the information themselves. A good user experience is essential to retain visitors, hence we give importance to it.</p>
<p>A website that can attract and retain visitors is good. A website should leave a good first impression. Therefore, Web development is an important step in achieving this experience.</p>
<p>Website development has completely changed the advertising field. If you want to learn about a company, you can find a summary of that company on the Internet. Building an interesting and persuasive website is crucial because it says a lot about how businesses operate.</p>
<p>It sounds easy, but striving to achieve these goals may be typical. But with the help of our professional team, you can easily achieve these results. It requires a high degree of concentration, alertness, and skill. At Livasys IT Solutions, we provide creative and dynamic web development services.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WebDevelopmentContainer.propTypes = {
    data: PropTypes.object
};

export default WebDevelopmentContainer;
