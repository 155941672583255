import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebDesigningCourseContainer from '../container/WebDesigningCourse/WebDesigningCourseContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const WebDesigningCourse = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Enroll in Our Best Web Designing Course in Hyderabad</title>
                <meta name="description" content="Our Web Designing Course in Hyderabad offers complete training and practical hands-on experience, furnishing enrolled students with the advanced skills. " />
                <meta name="keywords" content="Web Designing Course in Hyderabad, web development course in hyderabad, web design courses hyderabad, web designing course hyderabad, website design courses near me, fee for web designing course, web designing course, web development courses hyderabad, web design course in hyderabad, web design courses in hyderabad, web designing course, web designing, web designing sites, web designing course near me, web designing near me, responsive web designing, website design course, web designing course online, web design certificate, internet and web designing, web design and development course, web design classes, advanced web designing, web design training, web designing and development, html web designing, best web design courses, web design classes near me, learn web designing, web design courses online with certificate, udemy web design, web designing course after 12th, web designing and publishing, web designing course details, arena graphic design, web designing institute near me, website design training, coursera web design, web and graphic design course, web designing for beginners, web designing & development, web development and web designing, web development certificate online, website design classes, web designing tools for beginners, web design certificate online, best online web design courses, web designing" />
                <meta property="og:title" content="Enroll in Our Best Web Designing Course in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Web Designing Course"
                content="Home"
                contentTwo="Web Designing Course"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebDesigningCourseContainer/>
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebDesigningCourse;



