import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const ECommerceContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/32.webp" alt="ECommerce in Manchester" />
            </div>
            <div className="info">
                <h1 className="title">One of The best Top Notch E-Commerce Website Developers in Manchester</h1><br/>
                <h3 className="title">E-Commerce</h3>
                <div className="desc">
                    <p><strong>Livasys IT solution is well-known for one of the best high rated website designers in Hyderabad. We help you develop an online solution to reach your target audience and have a good impact with our stylish, attractive & modern website designs.</strong></p>

                    <p>With a lot of experience in website design, our professional team of website designers can easily understand the needs and obtain the best results according to customer expectations.</p>

                    <p>Our web design team works innovatively to develop many effective services to help you grow your business. The main goal of our team is to create a stunning and attractive website, which assists your website to encourage more customers to visit your website multiple times.</p>
                   
                    <h3>Benefits of having Web designing by our company</h3>
 

<h5>Provides Better SEO Strategies:</h5>
<p>We as one of the best website designers in Hyderabad know that Poorly designed or outdated websites can harm search engine rankings. In addition to the way you publish content on your website and certain elements of web design also have a direct impact on SEO. So, our company tries to provide SEO-friendly design. A service that helps your website rank high in search engines.</p>

<h5>Strong First Impression:</h5>
<p>Website design is important because it affects the audience’s perception of your brand. The impression you leave can lead them to stand on your side and learn more about your business or to leave your page and turn to a competitor. A good web design can help you retain potential customers. Therefore, we as one of the best website designers in Hyderabad ensures to create your website to make a strong and compelling first impression.</p>

<h5>Reduces Bounce Rate:</h5>
<p>If your website is professionally designed, your visitors are more likely to dig deeper. They are more likely to leave the homepage. Ideally, they should dig deeper and study the services your company must provide. With help of our professionally designed can not only attract visitors to your website but also help p them stay thereby studying the services provided by your business.</p>

<p>We as one of the best top-rated website designers ensure that from easy navigation to compelling calls-to-action, our holistic approach focuses on enhancing every aspect of your website to reduce bounce rates. We optimize page load times, ensuring swift and hassle-free interactions that engage your audience from the moment they arrive.</p>

<h5>Increases Revenues:</h5>
<p>As one of the most effective website designers in Hyderabad, we provide an attractive and strategic website that will attract more visitors and convert these visitors into potential customers. It will inevitably lead to increased sales, and your company will benefit from it. If you want to increase sales, our web services are a good place to invest in and increase your revenues.</p>

<p>Other than this if you pick Livasys IT solution for web designing services then other services included in it are Customized styling, Website copywriting, Responsive design, Database integration, e-commerce functionality, etc.</p>

<p>With our team of expertise and full-time support with multiple services, we hope to design a unique and attractive website to satisfy all your needs. So, join our team and let us build the most suitable web design to express your business objective quickly.</p>

<p>Contact us to start the process to build your presence online and boost more conversions with one of the best top rated website designers in Hyderabad.</p>

                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
ECommerceContainer.propTypes = {
    data: PropTypes.object
};

export default ECommerceContainer;
