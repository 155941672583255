import React from 'react';
import {Link} from "react-router-dom";
//import Logo from '../../components/logo/Logo';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';

const Footer = () => {
    return (
        <div className="footer-section section footer-bg-color">
            <div className="container">
                <div className="row mb-lg-14 mb-md-10 mb-6">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                            {/* <div className="footer-logo">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
                                />
                            </div> */}
                            
                            <div className="footer-widget-content">
                            <h4 className="footer-widget-title">Where to Find Us</h4>
                                <div className="content">
                                    <p><i className="fas fa-map-marker-alt"></i> # 83 Riverbrook Rd, West Timperley, Timperley, Altrincham WA14 5YH, UK
</p>

                                    <p><i className="fas fa-envelope"></i> <Link to={process.env.PUBLIC_URL + "/"}>info@livasys.co.uk</Link></p>
                                </div>
                                <div className="footer-social-inline">
                                    <a href="https://www.linkedin.com/company/livasys-it-solutions/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                                    <a href="https://www.facebook.com/LivasysIT/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {FooterData && FooterData.map((single, key) => {
                            return(
                                <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                    <FooterLinkItem data={single} key={key} />
                                </div>
                            ); 
                    })}


                </div>

                <div className="whatsapp-wrapper">
	<a href="https://wa.me/+447464554213" target="_blank" rel="noopener noreferrer"><img  src={process.env.PUBLIC_URL + "/images/icons/whatsapp-icon-new.png"} alt="whatsapp" /></a>
</div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">All Rights Reserved &copy; 2024 Made with <i className="fas fa-heart text-danger"></i> by <a target="_blank" rel="noopener noreferrer" href="https://livasys.co.uk/"><strong>Team Livasys</strong></a></p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
