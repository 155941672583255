import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import SearchEngineMarketingSEMContainer from '../container/SearchEngineMarketingSEM/SearchEngineMarketingSEMContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const SearchEngineMarketingSEM = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best Search Engine Marketing Services in Hyderabad India</title>
                <meta name="description" content="With our Search Engine Marketing services in Hyderabad India, increase your visibility by targeted advertising, get immediate results and generate good business" />
                <meta name="keywords" content="search engine marketing services in hyderabad, google ads service, google ads specialist, google ppc, google local ads, google ads agency, google pay per click, google ads management services, google ppc ads, google ads consultant, google ads company, google ads customer care, google adwords services, google ads agency near me, ads local, google ads for real estate, ads ppc, google campaign management, adwords management, google ad agency near me, google adwords management, youtube ads management, services ads, google leads services, google advertising management, managing google ads, advertise on search engines, google adwords account management, google advertising marketing, bing ads" />
                <meta property="og:title" content="Best Search Engine Marketing Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Social Media Marketing"
                content="Home"
                contentTwo="Social Media Marketing"
            />
            {/* <AboutFour />
            <Video /> */}
            <SearchEngineMarketingSEMContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default SearchEngineMarketingSEM;



