import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
// import {Link} from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom-50">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-3.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-4.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.webp"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle="Why Choose Livasys?"
                                title="We are here to manage your online presence with experience"
                            />

                            <p>If you are ready to ditch the digital doldrums and soar to new heights, then we are the rocket fuel you need. Contact us today for a free consultation, and lets craft your online empire together.</p><br/>

<p>Remember, Manchester vibrant digital landscape is your oyster. Let Livasys Digital be the pearl that elevates your business to the top of the heap.</p><br/><br/>
                            
                            {/* <p>Livasys IT Solutions helps organizations improve by leveraging the power of modern technology, cost-Effective Web Designs & eCommerce Solutions.</p> */}
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                    <br/><br/>
                            <p><b>Proven Track Record:</b> With a track record of delivering successful projects, we have earned the trust of clients across various industries. Check out our portfolio to witness the quality of our work.</p>

                            <p><b>Strategic Approach:</b> Our strategies are rooted in data-driven insights. We analyze market trends, conduct thorough research, and tailor our solutions to meet your specific needs.</p>

                            <p><b>Transparent Communication:</b> We believe in transparent communication throughout the project lifecycle. You willl always be informed about the progress of your project, and we are here to address any queries you may have.</p>

                            <p><b>Continuous Improvement:</b> The digital landscape is ever-changing, and we stay at the forefront of industry trends. Our team continuously updates their skills to bring you the latest innovations in web design and digital marketing.</p>

                            <p>Let Livasys Digital be your partner in success. Elevate your online presence, connect with your audience, and achieve your business objectives with our tailored solutions. Contact us today, and lets embark on a journey to digital excellence together.</p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFive;
