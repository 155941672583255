import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about-livasys-uk"}><span className="menu-text">About Us</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/our-work"}><span className="menu-text">Our Work</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/services"}><span className="menu-text">Our Services</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/website-design-agency"}><span className="menu-text">Website Design</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/e-commerce-websites"}><span className="menu-text">E-Commerce Websites</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/web-application-development`}><span className="menu-text">Web Applications</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/digital-marketing-agency"}><span className="menu-text">Digital Marketing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/search-engine-optimization-services"}><span className="menu-text">Search Engine Optimization (SEO) </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/domain-registration-services`}><span className="menu-text">Domains </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-hosting-service-provider"}><span className="menu-text">Hosting</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/graphic-design-services"}><span className="menu-text">Graphic Design</span></NavLink></li>
                    </ul>
                </li>
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/internship-hyderabad"}><span className="menu-text">Internships</span></NavLink>
                </li> */}
            </ul>
        </nav>
    )
}

export default NavBar
