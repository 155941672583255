import React from 'react';
// import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import ContactInformation from '../container/ContactInformation/ContactInformation';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';

const ThankYou = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Thank You For Contacting Us - Livasys IT Solutions | Hyderabad | India</title>
                <meta name="description" content="Contact us for all your digital needs. Our team is ready to assist you with web design & development, digital marketing, graphic design, domain & hosting services.services. " />
                <meta name="keywords" content="livasys, livasys it solutions contact, it company contact, it companies in hyderabad with contact details, it companies in hyderabad contact number, it companies in hyderabad contact numbers, it companies in hyderabad details, website designing company contact details" />
                <meta property="og:title" content="Contact Us - Livasys IT Solutions | Hyderabad | India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.webp"
                title="Thank You"
                content="Home"
                contentTwo="Contact Us"
            />
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1 desc  align-self-center text-center">
                <br/><br/><br/><br/>
                <h2 className=" align-self-center">Thank you for contacting us.</h2>
                <h4>One of our top executive will get in touch with you shortly and help you with your requirements.</h4>
                <br/><br/><br/></div>
            </div>
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default ThankYou;

