import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebApplicationDevelopmentContainer from '../container/WebApplicationDevelopment/WebApplicationDevelopmentContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const WebApplicationDevelopment = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best Web Application Development Services in Hyderabad India</title>
                <meta name="description" content="Improve your Business with our Custom Web Application Development Services in Hyderabad and India. State your requirements to start a project with efficiency..." />
                <meta name="keywords" content="web application development, web application development service, web application development company in india, custom web application development company, web application development company, web app development, web application development, backend web development, java web development, web app python, custom web application development services, web and app development, web and mobile app development, web app development company, custom web application development company, web app development services, web application development services, web application development company, front end programming, mobile web development" />
                <meta property="og:title" content="Best Web Application Development Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Web Application Development"
                content="Home"
                contentTwo="Web Application Development"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebApplicationDevelopmentContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebApplicationDevelopment;



