import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import LogoDesigningServicesContainer from '../container/LogoDesigningServices/LogoDesigningServicesContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const LogoDesigningServices = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Creative Logo Design Services Provider in Hyderabad India</title>
                <meta name="description" content="Our Logo Design Services provide Innovative, Creative, Attractive & Stylish Logo designs that Enhance your Brand identity and Recognition among People." />
                <meta name="keywords" content="logo design services, logo designer hyderabad, logo design, company logo design, professional photography logo, dental logos, logo for pharmacy, logo of electricity, logo design sa, design dj logo, logo for plumbing, 3d logo designing, best logo design, brand logo design, graphic design logo, professional logo design, cleaning logo, interior design logo, logistics logo, medical logo design, salon logo design, pharmacy logo design, creative logo designs, unique logo design, team logo design, dental logo design, shop logo design, house logo design, fish logo design, transportation logo, electrical logo design, engineering logo design, mobile logo design, brand identity designer, logo new design, construction logo designs, logo design with name, logo physiotherapy, infinite logo design, logo making company, logo design for photography, logo security guard, logo design artist, creative tailor logo design, creation logo design, creative dental logo, modern logo designer, travelling logo design, architectural design logo, logo for design studio, logo wedding design, design a logo for me, logo design for hotel, computer design logo, logo design for music, logo design makers, logo design boutique, letter designs for logos, car logo designer, fiverr logo design, free logo services. logo design near me, design your logo, logo service, best logo design company, cleaning company logo, it logo design, logo design cost, logo design agency, cargo logo, group logo design, mascot logo design, monogram logo design, digital logo design, security logo design, phoenix logo design, health logo design, hp logo design, corporate logo design, transport logo design, logo design hyderabad, logo designers in hyderabad" />
                <meta property="og:title" content="Creative Logo Design Services Provider in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Logo Designing Services"
                content="Home"
                contentTwo="Logo Designing Services"
            />
            {/* <AboutFour />
            <Video /> */}
            <LogoDesigningServicesContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default LogoDesigningServices;



