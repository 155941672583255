import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebHostingContainer from '../container/WebHosting/WebHostingContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const WebHosting = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>The Best Web Hosting Service Provider in Hyderabad India</title>
                <meta name="description" content="Make Your Online Presence With The Best Web Hosting Service Provider in Hyderabad, India. Experience Our Lightning-Fast Web Hosting Service and Get 24/7 expert support." />
                <meta name="keywords" content="Web hosting service provider, web hosting applications, cloud web hosting, basic web hosting, offshore dedicated servers, best managed wordpress hosting, check hosting provider, web hosting for small business, web page hosting, web hosting blog, best free web hosting sites, Web hosting services in India, web hosting companies, india web hosting services" />
                <meta property="og:title" content="The Best Web Hosting Service Provider in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Web Hosting"
                content="Home"
                contentTwo="Web Hosting"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebHostingContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebHosting;



