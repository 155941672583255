import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">Expert Team</AccordionTitle>
                    <AccordionContent id="one">Our team of skilled designers and developers is dedicated to providing you with the finest product experience in both website development and digital branding.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Best Digital Brand</AccordionTitle>
                    <AccordionContent id="two">We have earned recognition as one of the premier digital brands in the market, thanks to outstanding customer reviews.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">Creative Ideas</AccordionTitle>
                    <AccordionContent id="three">Our creatively adept managers offer excellent ideas that prove effective in the practical world.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
