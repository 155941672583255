import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebDevelopmentContainer from '../container/WebDevelopment/WebDevelopmentContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const WebDevelopment = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Top-Rated & Best Web Development Company In Hyderabad India</title>
                <meta name="description" content="We are the best top-rated web development company in Hyderabad India. We develop your website that elevate your business to new heights and drive conversions." />
                <meta name="keywords" content="web development company, web development hyderabad, web development agency, web development, web programming, web development services, front end web developer, python web development, back end web development, php web development, web app development, website development services, web developer near me, reactjs development company, java for web development, web developer portfolios, best web development company in hyderabad, web development in hyderabad, webdeveloper, html developer, front end dev, website design and development, best web development company, python backend developer, custom website development, css developer, ecommerce website development services, web development company near me, front end and back end developer, front end development services, website design & development, ecommerce web development services, ecommerce development agency, php web development company, seo web development, custom web application development services, custom web development company, python web programming, php web development services, outsource web development, top website development company, web development packages, web and mobile app development company, web design and development services, web design apps, ecommerce web development, best web developers, best web development software, frontend and backend developer, best ecommerce website development company, professional web development services, advanced web development, web developers in hyderabad, best web development company in hyderabad, web development in hyderabad" />
                <meta property="og:title" content="Top-Rated & Best Web Development Company In Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Web Development "
                content="Home"
                contentTwo="Web Development"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebDevelopmentContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebDevelopment;



