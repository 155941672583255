import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import GraphicDesigningContainer from '../container/GraphicDesigning/GraphicDesigningContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const GraphicDesigning = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best Top-Rated Graphic Design Services in Hyderabad India</title>
                <meta name="description" content="Our Graphic Design Services include Logo design, Creative Business Card design, Innovative Flyers design, Visually Appealing Brochures design, Banner & Poster" />
                <meta name="keywords" content="graphic designer services, graphic designer near me, motion graphics, digital design, freelance designer, graphic design art, graphic design company, graphic design near me, graphic design agency, graphic design poster, 3d graphic design, graphic design portfolios, creative graphic design, print designer, graphic design banner, advertising designer, graphic designer visiting card, brand designers, photoshop designer, graphic design quote, visual communication designer, photoshop graphic design, web graphic design, graphic design flyer, graphics and design, graphic design companies near me, graphic designers in hyderabad, graphic design company in hyderabad, famous graphic designers, multimedia designer, digital graphic design, senior graphic designer, marketing graphic design, graphic design studio, professional graphic design, graphic design prices, motion graphics animation, graphic design projects, graphic design business, visual graphic design" />
                <meta property="og:title" content="Best Top-Rated Graphic Design Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Graphic Designing"
                content="Home"
                contentTwo="Graphic Designing "
            />
            {/* <AboutFour />
            <Video /> */}
            <GraphicDesigningContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default GraphicDesigning;



