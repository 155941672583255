import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import BulkSMSServicesContainer from '../container/BulkSMSServices/BulkSMSServicesContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const BulkSMSServices = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>We Provides The Best Bulk SMS Service in Hyderabad India</title>
                <meta name="description" content="Livasys IT Solutions provides the best Bulk SMS Service in Hyderabad. Boost Your communication strategy and reach wide audience instantly with our Bulk SMS Service." />
                <meta name="keywords" content="bulk sms services in hyderabad, bulk sms service provider in hyderabad, bulk sms service, bulk sms service provider, bulk sms online, sms bulk online, sms gateway, bulk sms provider, bulk message service, bulk message, bulk sms gateway, sms service provider, bulk sms company, bulk sms service provider near me, bulk sms in hyderabad, bulk sms in hyderabad, sms gateway provider" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Bulk SMS Services"
                content="Home"
                contentTwo="Bulk SMS Services"
            />
            <BulkSMSServicesContainer />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BulkSMSServices;



