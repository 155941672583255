import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import SocialMediaOptimizationSMOContainer from '../container/SocialMediaOptimizationSMO/SocialMediaOptimizationSMOContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const SocialMediaOptimizationSMO = () => {
    return (
        <React.Fragment>
             <MetaTags>
                <title>Best Social Media Optimization Services in Hyderabad India</title>
                <meta name="description" content="Our Social Media Optimization services maximize your social media presence by optimizing your profiles, engaging with audience, driving targeted traffic, and brand awareness." />
                <meta name="keywords" content="social media optimization services, social media optimization services in hyderabad, , social media optimization, smo marketing, smo services, smo services in hyderabad, social media optimization company, social media optimization company in hyderabad, smo packages, smo companies" />
                <meta property="og:title" content="Best Social Media Optimization Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Social Media Optimization"
                content="Home"
                contentTwo="Social Media Optimization"
            />
            {/* <AboutFour />
            <Video /> */}
            <SocialMediaOptimizationSMOContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default SocialMediaOptimizationSMO;



