import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import DomainRegistrationServicesContainer from '../container/DomainRegistrationServices/DomainRegistrationServicesContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const DomainRegistrationServices = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best Domain Registration Services in Hyderabad India</title>
                <meta name="description" content="Livasys IT Solutions provides the best Domain Registration Services in Hyderabad, India. We offer seamless, reliable, and secure solutions with reasonable prices." />
                <meta name="keywords" content="Domain Registration Services in Hyderabad, googledomains, top level domain, expired domains, best domain registrar, buy domain name india, domain provider, best domain, domain registration best, buy domain india, best domain provider, domain availability search, domain registration details, best domain buying sites, domain registration services, domain name registration prices, book a domain, how to buy domain" />
                <meta property="og:title" content="Best Domain Registration Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Domain Registration Services"
                content="Home"
                contentTwo="Domain Registration Services"
            />
            {/* <AboutFour />
            <Video /> */}
            <DomainRegistrationServicesContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default DomainRegistrationServices;



