import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import OurClientsContainer from '../container/OurClients/OurClientsContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const OurWork = () => {
    return (
        <React.Fragment>
            <SEO title="Best Web Designing Company" />
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Our Work"
                content="Home"
                contentTwo="Our Work"
            />
            {/* <AboutFour />
            <Video /> */}
            <OurClientsContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default OurWork;



