import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const GraphicDesigningContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/1.webp" alt="graphic design services" />
            </div>
            <div className="info">
                <h1>Increase your Brand awareness with our Professional Graphic Design Services</h1>
                <h3 className="title">Graphic Design Services</h3>
                <div className="desc">
                    <p><strong>Whether you need a new logo or a redesign, brochures, posters, e-books, billboards, advertisements, packaging, business cards, website redesigns, or any variety of printable creative elements you can think of. We are there to fulfill all your needs. We understand and will always strive to make you happy with our creative and trendy design. Here are so of the details about the services we provide for you</strong></p>
<h2>Graphic Design Services that we Offer:</h2>
<h5>Logo Designing:</h5>
<p>Our graphic design services create an impressive corporate image by providing high-quality logo design services. Get a Unique and attractive logo at a favorable price. Our logo designers have many years of experience in creativity and flexibility and can create Logos that reflect your brand message.</p>

<h5>Brochures Design:</h5>
<p>Brochures are often considered old media in the digital age, but they are equally important. We provide graphic design to create brochures that are most suitable for travel diaries to attract the attention of customers while keeping them curious.</p>

<h5>Business Card Design:</h5>
<p>Through our graphic designing service, you can get a personalized business card that suits your business or personal preferences. Try our services we guarantee to provide you with excellent results and higher social value.</p>

<h5>Poster Design:</h5>
<p>When it comes to poster design, less is more. The poster should check that the most necessary message is loud and understandable to instantly attract the audience. We tend to meet your poster design wants with high-quality graphic design services. Our posters are designed to quickly and effectively attract the attention of the audience.</p>

<h5>Billboard Design:</h5>
<p>If you want an eye-catching billboard to attract the attention of passersby, we have offshore graphic design services that can provide you with high-quality billboard designs anytime, anywhere.</p>

<h5>E-Book Cover Design:</h5>
<p>We focus on professional e-book design, adding a range of elements, including text, audio, image, and video. Our design experts have created e-books that immediately attract customers through their vibrant style and colors.</p>

<p>Our graphic designers are experts in various design tools and have extensive experience in graphic design projects in all walks of life. Before starting the design, we collect information about your business needs and goals.</p>

<p>Then, we will check the requirements, vision, and value of your brand, your market competition, the demographics of your target group, etc. Based on market research and your suggestions, our designers begin to create innovative and customized designs that exceed your expectations.</p>

<p>With every project, we try to impress our customers with new and unique designs. So be part of our services to experience these surprising elements by our team.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
GraphicDesigningContainer.propTypes = {
    data: PropTypes.object
};

export default GraphicDesigningContainer;
