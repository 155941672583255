import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import PHPCourseContainer from '../container/PHPCourse/PHPCourseContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const PHPCourse = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>The Best PHP Training in Hyderabad offers Offline and Online</title>
                <meta name="description" content="Our PHP training in Hyderabad is conducted by the experienced instructors who guide students through real-world projects, provide valuable skills to boost careers." />
                <meta name="keywords" content="php training in hyderabad, php course in hyderabad, php training institute in hyderabad , php online training in hyderabad, laravel training in hyderabad, php institute in hyderabad, php institute in ameerpet , php training in ameerpet, best php training institute in hyderabad, php course in ameerpet, php course, php training, laravel course, php for beginners, udemy php, udemy laravel, php full course, learn php online, advanced php, laravel udemy, learning php mysql & javascript, best php course, php bootcamp, laravel advanced, learn php from scratch, php mysql class, php lessons, coursera php, html css javascript php mysql projects, udemy php course, advanced php tutorial, javascript php mysql, learning php mysql javascript css & html5, best php tutorial, php course for beginners, php from scratch, php course with certificate, php training course, php online training, html css javascript php mysql, object oriented programming with php, best php course on udemy, php full course in hindi" />
                <meta property="og:title" content="The Best PHP Training in Hyderabad offers Offline and Online" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="PHP Course"
                content="Home"
                contentTwo="PHP Course"
            />
            {/* <AboutFour />
            <Video /> */}
            <PHPCourseContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default PHPCourse;



