import React from 'react';
//import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebDesigningContainer from '../container/WebDesigning/WebDesigningContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const WebDesigning = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>One Of The Best Top-Rated Website Designers in Hyderabad</title>
                <meta name="description" content="We are one of the most effective website designers in Hyderabad, We Transform Your Online Presence with our Creative, Attractive & Modern Website Designs" />
                <meta name="keywords" content="Website designers in hyderabad, google web designer, creative website design, simple html web page design, website design company, landing page designer, html web design, web layout design,web design services, best website design, best portfolio websites, web design and development, graphic design website, html & css design and build web sites, ecommerce website design, freelance web designer, website design services, portfolio website design, html coding for website design, website banner design, website ui design, web ui design, low cost web design in hyderabad, website designers in hyderabad, html and css design and build websites, web design near me, website design near me, web development agency, modern website design, web design portfolio, custom web design, cool website designs, seo website design, business website design, graphic design portfolio websites, professional website designer, web design & development, web design business, e commerce web design, basic website design, top web design companies, website design for small business, best ecommerce website design, html & css design and build websites, website landing page design, website design and development company, advanced web designing, web design near me, best web designers in hyderabad, best website designers in hyderabad, best web designing company in hyderabad, website designing company in hyderabad, freelance web designer in hyderabad, web designing company in hyderabad, website design businesses, website design companies in india, website design company hyderabad, website design and development companies, website designing services india, website developer company in india, website development agency in india, website development services near me, top web design company india, web design companies in hyderabad, web design services in hyderabad" />
                <meta property="og:title" content="One Of The Best Top-Rated Website Designers in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" /> 
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Web Designing"
                content="Home"
                contentTwo="Web Designing"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebDesigningContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebDesigning;



