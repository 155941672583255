import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import SearchEngineOptimizationSEOContainer from '../container/SearchEngineOptimizationSEO/SearchEngineOptimizationSEOContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const SearchEngineOptimizationSEO = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best Search Engine Optimization Services in Hyderabad</title>
                <meta name="description" content="Our Search Engine Optimization Services in Hyderabad, Improve your website's visibility in (SERPs) by optimizing your website's content, structure, and technical aspects." />
                <meta name="keywords" content="search engine optimization services in hyderabad, seo agency in hyderabad, best seo company in hyderabad, seo companies in hyderabad, seo consulting india, seo services, seo optimization, seo marketing, on page seo, seo expert, seo packages, seo search engine optimization, seo digital marketing, seo companies, on page and off page seo, search engine optimization in digital marketing, on page seo and off page seo, search engine optimisation seo, search engine optimization marketing, seo solutions, best seo service, seo service company, on page off page seo, search engine optimization company, seo package prices, mobile seo, seo on page optimization, organic seo services, best seo websites, ecommerce seo packages, seo off page optimization, on page seo optimization, search engine optimisation in digital marketing, seo best company, page ranking seo, seo tech expert, seo consultant, affordable seo package, seo company near me, local seo company, google search engine optimization, website seo optimization, professional seo company, seo optimisation, on site seo, off page optimization, seo agency near me, engine optimization, keyword optimization, search engine optimization services, seo sem marketing, seo and digital marketing, seo expert near me, best seo agency, local seo agency, top seo agency, monthly seo packages, best seo expert, seo optimized content" />
                <meta property="og:title" content="Best Search Engine Optimization Services in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Search Engine Optimization"
                content="Home"
                contentTwo="Search Engine Optimization"
            />
            {/* <AboutFour />
            <Video /> */}
            <SearchEngineOptimizationSEOContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default SearchEngineOptimizationSEO;



