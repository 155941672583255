import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContentManagementSystemContainer from '../container/ContentManagementSystem/ContentManagementSystemContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const ContentManagementSystem = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>One of The Best Cms Development Companies in Hyderabad India</title>
                <meta name="description" content="Livasys IT Solutions is one of the best Cms Development Companies in Hyderabad, India. We Manage your website's content with our best content management system." />
                <meta name="keywords" content="cms development companies, cms development services, cms development service, cms website development, cms web development, cms for web development, cms for website development, cms development, cms developer, cms online, cms it services pvt ltd, drupal developers, magento developer, localhost wordpress, wordpress developer, cms wordpress" />
                <meta property="og:title" content="One of The Best Cms Development Companies in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp" 
                title="Content Management System "
                content="Home"
                contentTwo="Content Management System"
            />
            <ContentManagementSystemContainer />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default ContentManagementSystem;



