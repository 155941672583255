import React from 'react';
/**import SEO from '../components/SEO';**/
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Privacy Policy - Livasys IT Solutions | Hyderabad | India</title>
                <meta name="description" content="A one-stop solution for all your digital needs. From web design and development to digital marketing, graphic design, domain and hosting services." />
                <meta name="keywords" content="livasys, livasys it solutions, it company, it company in hyderabad, it company in india, best it company in hyderabad, top it company in hyderabad, best it company in india, top it company in india" />
                <meta property="og:title" content="About Us - Livasys IT Solutions | Hyderabad | India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Privacy Policy"
                content="Home"
                contentTwo="Privacy Policy"
            />
            <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        <div className=" mt-0 mb-md-10 mb-10">
                            <p>Last Updated: [03-10-2023]</p>

                            <p>1. Introduction

Welcome to Livasys IT Solutions (we,our,or,us). At Livasys IT Solutions, we value your privacy and are committed to protecting your personal information. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you use our website located at www.livasys.com (the Website) and our services.

By accessing or using the Website and our services, you consent to the practices described in this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Website or services.</p>

<p>2. Information We Collect

2.1. Personal Information: We may collect personal information that you provide to us, including but not limited to your name, email address, postal address, phone number, and other contact information.

2.2. Usage Information: We may collect information about your usage of our Website and services, including your IP address, browser type, operating system, and browsing behavior.

2.3. Cookies: We use cookies and similar tracking technologies to collect information about your browsing activities. You can control cookies through your browser settings.</p>

<p>3. How We Use Your Information

We may use your personal information for various purposes, including:

3.1. Providing and improving our services.

3.2. Responding to your inquiries and requests.

3.3. Sending you promotional materials and updates if you have opted in to receive them.

3.4. Analyzing and improving the user experience on our Website.</p>

<p>4. Information Sharing

We may share your personal information with third parties in the following circumstances:

4.1. With service providers and partners who help us operate and improve our services.

4.2. To comply with legal obligations or respond to lawful requests from authorities.

4.3. In connection with a merger, sale, or other business transaction.</p>

<p>5. Your Choices

You have the following choices regarding your personal information:

5.1. You can access, correct, or delete your personal information by contacting us.

5.2. You can opt out of receiving promotional communications from us.</p>

<p>6. Security

We use reasonable administrative, technical, and physical measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>

<p>7. Childrens Privacy

Our services are not directed to individuals under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.</p>

<p>8. Changes to this Privacy Policy

We may update this Privacy Policy from time to time. Any changes will be posted on our Website with the revised date.</p>

<p>9. Contact Us

If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

<p>Livasys IT Solutions</p>
<p>#403/C, 4th Floor, Mustafa Towers, Lakdikapul, Hyderabad, Telangana, India.</p>
<p>info@livasys.com<br/><br/></p>
                            
                        </div>
                    </div>

                    
                    

                </div>

            </div>
        </div>
            
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default PrivacyPolicy;



