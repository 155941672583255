import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import CoursesContainer from '../container/Courses/CoursesContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const Courses = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Livasys IT Solutions Provides Exclusive Courses in Hyderabad</title>
                <meta name="description" content="Livasys IT Solutions offers Web Design, Web Development, PHP, and Digital Marketing Courses, with Offline and Online Classes in Hyderabad. Elevate Your Career with us.
" />
                <meta name="keywords" content="courses in hyderabad, livasys courses, offline courses in hyderabad, online courses in hyderabad, wordpress training in hyderabad, wordpress course in hyderabad, digital marketing courses in hyderabad, it courses in hyderabad, after inter courses in hyderabad" />
                <meta property="og:title" content="Livasys IT Solutions Provides Exclusive Courses in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Courses"
                content="Home"
                contentTwo="Courses"
            />
            <CoursesContainer />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Courses;



