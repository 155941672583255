import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WebHostingContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/7.webp" alt="web hosting service provider" />
            </div>
            <div className="info">
                <h1>Improve search engine rankings, conversions and Brand Perception by our Web Hosting Service Provider Company</h1>
                <h2 className="title">Our Web Hosting service provider Company</h2>
                <div className="desc">
                    <p><strong>Livasys IT solutions is the best web hosting service provider in Hyderabad India, that provide affordable and reliable web hosting service for various needs. Our managed web hosting packages are fast, secure, and deliver first-class personal support.</strong></p>

                    <p>Availability: We guarantee the highest access speed and the best service. The web server is monitored by our experienced technical team 24 hours a day, 7 days a week, ensuring availability of more than 99.9%. Our hosting plan gives you everything you need to get online at an unbeatable value.</p>
                    

                    <h3>Benefits of Good Web Hosting Services:</h3>
 

<h5>Improved Site Performance:</h5>
<p>When it comes to online success, the performance of your website is essential. If your website loads endlessly, your visitors will not stand still, and your web ranking will lack. But as the best hosting service provider we can help you improve the performance of your website.</p>

<h5>Outstanding Technical Support:</h5>
<p>When you provide services or products, timely and handy customer support plays a vital role in your services. Our web hosting service provider company can not only help you provide the best 24/7 customer support, but also help you respond quickly in the event of any malfunction or crashes.</p>

<h5>Improved Website Security:</h5>
<p>Website security is something numerous website owners have never considered, but it is very important to the website. Our high-quality web hosting service provider company has multiple layers of security. We take measures, such as security audits, virus scanning and required operating systems, renew, and spam filtering, so you don’t have to worry about it.</p>

<h5>Liberty in Web Design and Templates: </h5>
<p>Web design is hands down among the most crucial elements of developing a website. We believe in providing our customers the space to design and add templates to the website according to their needs. It helps to construct the website which suits the company the best.</p>

<h5>Domain-Associated E-Mail Address:</h5>
<p>If you need to appear professional, you need a branded email address that uses your area name. At our web hosting service provider company, we assist you to create your very own domain e-mail address. We additionally offer many integrated emails management gears so that you can effortlessly manage your data.</p>
<p>For most businesses today, their website is their main sales channel, so we provide super-fast and reliable website hosting services that will ensure that you would not face any problems in the future.</p>
<p>Whether you choose a shared solution or a cloud-based solution, you can feel safe to join hands with Livasys IT Solutions.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WebHostingContainer.propTypes = {
    data: PropTypes.object
};

export default WebHostingContainer;
