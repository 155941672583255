import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import DigitalMarketingCourseContainer from '../container/DigitalMarketingCourse/DigitalMarketingCourseContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const DigitalMarketingCourse = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Enroll in Our Best Digital Marketing Course in Hyderabad</title>
                <meta name="description" content="Join our Digital Marketing Course in Hyderabad to unlock the secrets of effective digital strategies, learn SEO and SMM to content creation and data analytics." />
                <meta name="keywords" content="digital marketing course in hyderabad, best digital marketing institute in hyderabad, best institute for digital marketing in hyderabad, digital marketing course fees in hyderabad, digital marketing course in hyderabad fees, top digital marketing institute in hyderabad, digital marketing courses in hyderabad fees, digital marketing training institute in hyderabad, digital marketing course in ameerpet, digital marketing course ameerpet, free digital marketing course in hyderabad, free digital marketing courses in hyderabad, digital marketing course in hyderabad ameerpet fees, digital marketing courses in hyderabad with placements, digital marketing institute near me, institute of digital marketing, digital marketing training in hyderabad, digital marketing training in dilsukhnagar, digital marketing course in ameerpet, top digital marketing institute in hyderabad, digital marketing training institute in hyderabad, digital marketing course in dilsukhnagar, hyderabad digital marketing, digital marketing institute in hyderabad" />
                <meta property="og:title" content="Enroll in Our Best Digital Marketing Course in Hyderabad" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Digital Marketing Course"
                content="Home"
                contentTwo="Digital Marketing Course"
            />
            <DigitalMarketingCourseContainer />
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default DigitalMarketingCourse;



