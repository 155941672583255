import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
//import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
//import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
//import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const OurClientsContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-12 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                    <a href="../images/clients/livasys-webdesign-1.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-1.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-2.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-2.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-3.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-3.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-4.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-4.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-5.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-5.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-6.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-6.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-7.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-7.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-8.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-8.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-9.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-9.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-10.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-10.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-11.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-11.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-12.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-12.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-13.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-13.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-14.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-14.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-15.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-15.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-16.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-16.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-17.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-17.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-18.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-18.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-19.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-19.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-20.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-20.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-21.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-21.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-22.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-22.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-23.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-23.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-24.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-24.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-25.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-25.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-26.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-26.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-27.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-27.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-28.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-28.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-29.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-29.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-30.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-30.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-31.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-31.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-32.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-32.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-33.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-33.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-34.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-34.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-35.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-35.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-36.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-36.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-37.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-37.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-38.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-38.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-39.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-39.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            <div className="col mb-6 aos-init aos-animate" data-aos="fade-up">
                                <div className="icon-box text-center box-border portfolio-box">
                                <a href="../images/clients/livasys-webdesign-40.webp" target='_blank'><img className="w-100" src="../images/clients/livasys-webdesign-40.webp" alt="Client 1" /></a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
OurClientsContainer.propTypes = {
    data: PropTypes.object
};

export default OurClientsContainer;
