import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebsiteRedesignServicesContainer from '../container/WebsiteRedesignServices/WebsiteRedesignServicesContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const WebsiteRedesignServices = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>One Of The Best Website Redesign Agencies In Hyderabad India</title>
                <meta name="description" content="We are one of the most effective Website Redesign Agencies in Hyderabad, India. Redesign your website with our Professional designers and Boost your Online Presence." />
                <meta name="keywords" content="website redesign agencies, ux website redesign, custom website redesign, ecommerce website redesign, website redesign company, website redesign services, website redesign, website redesign business" />
                <meta property="og:title" content="One Of The Best Website Redesign Agencies In Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Website Redesign Services"
                content="Home"
                contentTwo="Website Redesign Services"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebsiteRedesignServicesContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebsiteRedesignServices;



