import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import {Link} from "react-router-dom";

const CallToActionTwo = () => {
    return (
        <div className="cta-section section section-padding-250">
            <div className="container text-center icon-up-down-animation">
                <SectionTitle
                    headingOption="fz-34"
                    title="Let's talk about your project and see how we can work together"
                    subTitle="Our team of designers, developers and creatives are perfectionists
                    who love what they do and love where they work"
                />
                <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + "../contact"} data-aos="fade-up" data-aos-delay="300">Contact Us</Link>

                <div className="shape shape-1">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../../images/icon-animation/1.webp"}  alt="MySql Projects" />
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../../images/icon-animation/2.webp"} alt="Hadoop Projects" />
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../../images/icon-animation/3.webp"}  alt="CodeIgniter Projects" />
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/4.webp"}  alt="Laravel Projects" />
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/5.webp"}   alt="NodeJs Projects" />
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/6.webp"}  alt="Php Projects" />
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/7.webp"}   alt="ReactJs Projects" />
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/8.webp"}  alt="AngularJs Projects" />
                    </span>
                </div>
                <div className="shape shape-9">
                    <span>
                        <img src={process.env.PUBLIC_URL + "../images/icon-animation/9.webp"}   alt="WordPress Projects" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CallToActionTwo;
