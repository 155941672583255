import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFour = () => {
    
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo 
                                subTitle="Web Design and Digital Marketing"
                                title="We are here to manage your online presence with experience"
                            />
                            <p>Welcome to Livasys Digital Branding Agency, a leading Website Design and Digital Marketing company based in the vibrant city of Manchester. At Livasys Digital, we bring a dynamic blend of creativity and technical expertise to help businesses thrive in the digital landscape.<br/><br/></p>
                            <p>At Livasys Digital, we are not just about numbers and algorithms. We are about building genuine relationships with our clients. We take the time to understand your business, your goals, and your target audience. We become an extension of your team, invested in your success as much as you are. Fuelled by passion, innovation, and a commitment to excellence, we take pride in crafting visually stunning websites that not only captivate your audience but also drive measurable results. </p>

                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-1.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/about-2.webp"} alt="livasys it solutions" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.webp"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                    <h3>Website Design:</h3>
<p>Our team of experienced designers ensures that every website we create is not just visually appealing but also optimized for superior performance. We understand that a well-designed website is the cornerstone of your online identity, and we work tirelessly to make sure it reflects the essence of your brand.</p>

<h3>Digital Marketing:</h3>
<p>In the ever-evolving digital landscape, our Digital Marketing services are geared towards enhancing your online visibility and driving targeted traffic. From strategic SEO campaigns to engaging social media management, we tailor our services to align with your business goals, ensuring a robust online presence that converts visitors into customers.</p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFour;
