import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WebMaintainanceContainer from '../container/WebMaintainance/WebMaintainanceContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const WebMaintainance = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Most Affordable Website Maintenance Company in Hyderabad India</title>
                <meta name="description" content="We are one of the most affordable Website Maintenance Company in Hyderabad, India. Discover our cost and packages to start optimizing the performance, security & user experience." />
                <meta name="keywords" content="website maintenance company, website maintenance, website maintenance cost, web maintenance, site maintenance, wordpress website maintenance services, website maintenance packages, wordpress maintenance services, website maintenance services, maintenance of website cost, maintenance cost of website, maintenance cost of website, website maintenance fee cost, website maintenance costs, cost of website maintenance, maintenance website cost, website maintenance cost, cost for website maintenance, website maintenance cost, website maintenance, website maintenance packages, website maintenance company" />
                <meta property="og:title" content="Most Affordable Website Maintenance Company in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Websit Maintainance"
                content="Home"
                contentTwo="Website Maintainance"
            />
            {/* <AboutFour />
            <Video /> */}
            <WebMaintainanceContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WebMaintainance;



