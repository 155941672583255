import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WhatsappMarketingServicesContainer from '../container/WhatsappMarketingServices/WhatsappMarketingServicesContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const WhatsappMarketingServices = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best WhatsApp Marketing Services in Hyderabad India</title>
                <meta name="description" content="Livasys IT Solutions provides the best Whatsapp Marketing Services that transform your Business with High-Performance, Maximize Your Reach and Connect with Customers." />
                <meta name="keywords" content="WhatsApp Marketing Services, whatsapp advertising, bulk whatsapp marketing, whatsapp promotion message, whatsapp promotion, whatsapp marketing for business, whatsapp business marketing, whatsapp for business marketing, business whatsapp promotion message, whatsapp advertising messages" />
                <meta property="og:title" content="Best WhatsApp Marketing Services in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Whatsapp Marketing Services"
                content="Home"
                contentTwo="Whatsapp Marketing Services"
            />
            {/* <AboutFour />
            <Video /> */}
            <WhatsappMarketingServicesContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WhatsappMarketingServices;



