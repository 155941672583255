import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import WordpressDevelopmentContainer from '../container/WordpressDevelopment/WordpressDevelopmentContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';






const WordpressDevelopment = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Best WordPress Development Company In Hyderabad India</title>
                <meta name="description" content="Partner With Us For Wordpress Development Services in Hyderabad, India, and Experience the Difference Our Expertise Can Make in Elevating Your Online Presence." />
                <meta name="keywords" content="wordpress development, wordpress website, java web development, wordpress designer, staging site, wordpress development services, wp website, woocommerce developer, wordpress expert, staging website, custom wordpress development, wordpress ecommerce website, wordpress website checker, custom wordpress development services, multi site wordpress, php wordpress developer, wordpress development services, wordpress development company, wordpress content management system, wordpress website development company, cms web development, custom wordpress development, wordpress web development company, content management system website, custom wordpress development services, cms development services, cms website development, best cms for website, cms development company, wordpress web design , wordpress website designer, wordpress web developer , wordpress website development company, wordpress web development company, wordpress website development services" />
                <meta property="og:title" content="Best WordPress Development Company In Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Wordpress Development"
                content="Home"
                contentTwo="Wordpress Development"
            />
            {/* <AboutFour />
            <Video /> */}
            <WordpressDevelopmentContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WordpressDevelopment;



