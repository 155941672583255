import React from 'react';
// import SEO from '../components/SEO';
import MetaTags from 'react-meta-tags';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import LocalSEOContainer from '../container/LocalSEO/LocalSEOContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const LocalSEO = () => {
    return (
        <React.Fragment>
             <MetaTags>
                <title>Best Local SEO Services Company in Hyderabad India</title>
                <meta name="description" content="With our best Local SEO Services, enhance your business visibility, attract targeted audience, optimize Google My Business profile and increase website visits." />
                <meta name="keywords" content="local seo services, google business profile, google business account, my business profile, google business page, google business listing, google my business account, google my business free, google listing, google business profile installation, add business to google, google my business page, google listing, local seo , local seo company, local seo company in hyderabad" />
                <meta property="og:title" content="Best Local SEO Services Company in Hyderabad India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Local SEO"
                content="Home"
                contentTwo="Local SEO"
            /> 
            {/* <AboutFour />
            <Video /> */}
            <LocalSEOContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default LocalSEO;



