import PropTypes from "prop-types";
import React from 'react';
//import {Link} from "react-router-dom";
//import BlogDetails from '../../components/Blog/BlogDetails.jsx';
//import Comment from '../../components/Comment/Comment.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
//import SidebarTitle from '../../components/Sidebar/SidebarTitle';
//import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
//import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
//import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
//import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const WebMaintainanceContainer = () => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                <img className="w-100" src="../images/blog/770/4.webp" alt="website maintenance company" />
            </div>
            <div className="info">
                <h1>Most affordable Website Maintenance Company in hyderabad, India.</h1>
                <h3 className="title">Web Maintainance</h3>
                <div className="desc">
                    <p><strong>Website maintenance service include viewing, editing, or modifying existing web pages to keep your website fresh and up to date. Website maintenance is essential to keeping the website up-to-date and functional.</strong></p>

                    <p>We as the most affordable website maintenance company, work hard to help you keep your site running efficiently. Our motive and purpose is to provide the best service to our clients.</p>

                    <p>The main goal of any website is to increase sales and keep your customers in touch with your website. This goal can only be accomplished when you place your website under easy-to-use features. Small business owners need to keep their websites up to date.</p>

                    <p>In this way, you can interact with potential consumers and visitors to your website and avoid major problems with your website.</p>
                   
                    <h3>Why go for our Website Maintenance Company?</h3>
 

<h5>Search Engine Optimization(SEO): </h5>
<p>Whether you are looking for custom theme development or plugin development, we have the best developers to take up your project and work on it as per your requirements. We bring the ideas into reality with our excellent level of customization and development in WordPress. Our core WP team will work closely with our clients and help them accomplish the exact results as per their requirements.</p>

<h5>Customer Attraction/ engagement:  </h5>
<p>If you are looking for a custom design for your business website, we as the most affordable website company design the same in photoshop and then turn it into a fully functional website in WordPress. Our team loves to work on custom projects to explore the new requirements with exceptional creative and workable solutions in WordPress.</p>

<h5>Security:</h5>
<p>WordPress security can be compromised easily if you are not following certain security measures during the installation and development process, and when it comes to an e-commerce or business website which involves a payment gateway one needs to make sure that all the security measures are taken just to cut out the risk of getting your website hacked or attacked with malicious code. Our website maintenance company ensure you that our WordPress experts will be taking care of all the necessary measures to make sure that the payment gateway and the website is secure and does not possess any kind of vulnerability in the website.</p>

<h5>Corporate Image:</h5>
<p>WordPress migration is not an easy task until and unless you are pro at it. Our website maintenance company and our team of WordPress developers makes it easy for you to migrate from one hosting to another with our hassle-free process of WP migration. Whether it’s a basic hosting or cloud server like AWS, our team will help you in both the setup and migration of the WP website and its smooth functioning.</p>

<h5>Pay close attention to design and technology trends:</h5>
<p>The more beautiful your website, the better you can convince potential customers that you are the best choice. Be the first to introduce new website technologies that help keep the server running smoothly. By regularly installing software updates, you can take advantage of the new features that come with the update. You can also change your design to keep up with the latest website design.</p>

<p>Our Website Maintenance Company provides website maintenance services with affordable monthly plans. Contact us to know the new monthly package.</p>





                </div>
            </div>
        </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            {/* <SidebarWidget>
                                <SidebarSearch />
                            </SidebarWidget>
                            <SidebarWidget>
                                <SidebarTitle title="Categories" />
                                 <SidebarCategories />
                            </SidebarWidget> */}
                            <div className="sidebar-widget">
                                <h3 className="sidebar-widget-title sidebar-widget-title">Our Services</h3>
                                <div className="sidebar-widget-content">
                                <ul className="sidebar-widget-cate-list">
                                        <li><a href="/best-web-designing-company"><span className="text">Web Designing</span></a></li>
                                        <li><a href="/web-development-company"><span className="text">Web Development</span></a></li>
                                        <li><a href="/web-maintainance-company"><span className="text">Website Maintenance</span></a></li>
                                        <li><a href="/website-redesign-agencies"><span className="text">Website ReDesign</span></a></li>
                                        <li><a href="/wordpress-development"><span className="text">WordPress Development</span></a></li>
                                        <li><a href="/digital-marketing-agency-in-hyderabad"><span className="text">Digital Marketing</span></a></li>
                                        <li><a href="/search-engine-optimization-services-in-hyderabad"><span className="text">SEO</span></a></li>
                                        <li><a href="/web-hosting-service-provider"><span className="text">Web Hosting</span></a></li>
                                        <li><a href="/web-application-development"><span className="text">Web Application Development</span></a></li>
                                        <li><a href="/logo-design-services"><span className="text">Logo Designing</span></a></li>
                                        <li><a href="/social-media-marketing-company-in-hyderabad"><span className="text">Social Media Marketing</span></a></li>
                                        <li><a href="/wordpress-training-in-hyderabad"><span className="text">WordPress Training</span></a></li>
                                        <li><a href="/web-designing-course-in-hyderabad"><span className="text">Web Development Training</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <SidebarWidget>
                                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                                <SidebarPost />
                            </SidebarWidget> */}
                            <SidebarWidget>
                                <SidebarBanner />
                            </SidebarWidget>
                            {/* <SidebarWidget>
                                <SidebarTitle title="Popular tags" />
                                <SidebarTag />
                            </SidebarWidget> */}
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
WebMaintainanceContainer.propTypes = {
    data: PropTypes.object
};

export default WebMaintainanceContainer;
